.search {}

@media (min-width: 1201px) {
    .search {
        width: 50vw;
        max-width: 1024px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .search {
        width: 60vw;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .search {
        width: 70vw;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .search {
        width: 80vw;
    }
}

@media (max-width: 576px) {
    .search {
        width: 90vw;
    }
}