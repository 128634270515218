.searchbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;

        &>* {
            height: 40px;
        }

        input {
            padding: 0 10px;
            border-radius: 5px;
            font-size: 16px;
            color: var(--blue);
            outline: none;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            width: 100%;
        }

        button {
            border-radius: 10px;
            background: var(--blue);
            color: #fff;

            &.search {
                padding: 0 20px;
            }
        }
    }

    .error {
        color: var(--red);
    }
}

@media (min-width: 1201px) {
    .searchbar {
        & form {
            width: 50vw;
            max-width: 1024px;
        }
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .searchbar {
        & form {
            width: 60vw;
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .searchbar {
        & form {
            width: 70vw;
        }
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .searchbar {
        & form {
            width: 80vw;
        }
    }
}

@media (max-width: 576px) {
    .searchbar {
        & form {
            width: 90vw;
        }
    }
}