.forecast {
    width: 100%;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
        }
    }

    &__date {
        color: var(--grey);
    }

    &__temperature {
        color: var(--grey)
    }

    &__temperature,
    &__description {
        font-size: 14px;
    }

    &__date+&__row,
    &__row+&__date {
        margin-top: 20px;
    }
}

@media (max-width: 576px) {
    .forecast {

        &__row {
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;

            div {
                width: 100%;
                justify-content: flex-end;
            }
        }
    }
}